(function () {
  const DOM = {
    sections: document.querySelectorAll(".observe"),
    nav: document.querySelector(".nav"),
    header: document.querySelector(".header"),
    body: document.querySelector("body"),
    htmlDoc: document.querySelector("html"),
  };

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  /*************** OBSERVER ***************/
  const observeSections = () => {
    const sectionObserverOptions = {
      root: null,
      threshold: isTouchDevice() ? 0 : 0.2,
    };

    const fadeInSections = (entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;

        entry.target.style.animation = `fadeInFromBottom .6s .2s forwards ease-in`;

        observer.unobserve(entry.target);
      });
    };

    const sectionObserver = new IntersectionObserver(
      fadeInSections,
      sectionObserverOptions
    );

    DOM.sections.forEach((section) => {
      sectionObserver.observe(section);
    });
  };

  const observeNav = () => {
    const navObserverOptions = {
      root: null,
      threshold: 0,
    };

    const stickyNav = (entries) => {
      const [navEntry] = entries;

      navEntry.intersectionRatio <= 0
        ? DOM.nav.classList.add("fixed", "container")
        : DOM.nav.classList.remove("fixed", "container");
    };

    const navObserver = new IntersectionObserver(stickyNav, navObserverOptions);
    navObserver.observe(DOM.header);
  };

  /*************** NAV BAR ***************/
  const responsiveNavigation = () => {
    const navLinks = DOM.nav.querySelectorAll(".nav__link");
    const navLinksContainer = DOM.nav.querySelector(".nav__links");
    const menuBtn = DOM.nav.querySelector(".nav__toggle");

    menuBtn.addEventListener("click", () => {
      menuBtn.classList.toggle("nav__toggle--close");

      if (navLinksContainer.dataset.open === "true") {
        navLinksContainer.dataset.open = "false";
      } else if (navLinksContainer.dataset.open === "false") {
        navLinksContainer.dataset.open = "true";
      }

      DOM.body.style.overflowY =
        navLinksContainer.dataset.open === "true" ? "hidden" : "visible";

      DOM.body.classList.toggle("stop-scroll");
      DOM.htmlDoc.classList.toggle("stop-scroll");
    });

    navLinks.forEach((link) => {
      link.addEventListener("click", () => {
        if (navLinksContainer.dataset.open === "true") {
          menuBtn.classList.toggle("nav__toggle--close");
          navLinksContainer.dataset.open = "false";
          DOM.body.style.overflowY = "visible";
          DOM.body.classList.remove("stop-scroll");
          DOM.htmlDoc.classList.remove("stop-scroll");
        }
      });
    });
  };

  /*************** PORTFOLIO SECTION ***************/
  const portfolioSection = () => {
    const DISPLAY_NUM_ITEMS = 2; // will display N more items
    const filterButtons = document.querySelectorAll(".filter__item");
    const projects = [...document.querySelectorAll(".project")];
    let filteredProjects = projects;
    const btnLoadMore = document.querySelector(".projects__load");

    const hideNumOfProjects = (items, num) => {
      items.slice(num).forEach((item) => {
        item.classList.add("hidden");
      });
    };

    hideNumOfProjects(projects, DISPLAY_NUM_ITEMS);

    const highlightFilteredTool = (currentBtn) => {
      filterButtons.forEach((b) => b.classList.remove("filter__item--active"));
      currentBtn.classList.add("filter__item--active");
    };

    const displayFilteredProjects = () => {
      projects.forEach((project) => {
        project.classList.add("hidden");

        // animation: fadein 0.5s 0.1s forwards ease-in;
      });

      filteredProjects
        .slice(0, DISPLAY_NUM_ITEMS)
        .forEach((project) => project.classList.remove("hidden"));
    };

    filterButtons.forEach((btn) => {
      btn.addEventListener("click", function (e) {
        highlightFilteredTool(e.target);

        btnLoadMore.disabled = false;

        const filteredTool = e.target.dataset.filter;

        if (!filteredTool) return;

        filteredProjects = projects.filter((proj) =>
          proj.dataset.category.includes(filteredTool)
        );

        if (filteredProjects.length === 0) return;

        displayFilteredProjects();

        const inactiveProjects = filteredProjects.filter((item) =>
          item.classList.contains("hidden")
        );

        if (inactiveProjects.length === 0) btnLoadMore.disabled = true;
      });
    });

    btnLoadMore.addEventListener("click", () => {
      const inactiveProjects = filteredProjects.filter((item) =>
        item.classList.contains("hidden")
      );

      inactiveProjects.slice(0, DISPLAY_NUM_ITEMS).forEach((item) => {
        item.classList.remove("hidden");
      });

      if (inactiveProjects.length <= DISPLAY_NUM_ITEMS)
        btnLoadMore.disabled = true;
    });
  };

  /*************** CONTACT FORM ***************/
  const contactForm = () => {
    const INVALID_EMAIL_ERROR = "Please enter a valid email!";
    const INVALID_NAME_ERROR = "Please enter a valid name!";
    const EMPTY_INPUT_ERROR = "This field cannot be empty!";
    const FORM_SUCCESS_MSG = "Your message was successfully sent.";
    const FORM_FAIL_MSG =
      "Sorry, failed to send your message. Please try again later.";

    const nameInput = document.querySelector("#name");
    const emailInput = document.querySelector("#email");
    const messageInput = document.querySelector("#message");
    const form = document.querySelector("form");
    const formStatus = document.querySelector(".form__status");
    const msgBox = document.querySelector("#message");

    let formStatusMessageTimeout = null;
    const FORM_MSG_STATUS_TIMER = 8;

    let isNameInputValid = false;
    let isEmailInputValid = false;
    let isMsgInputValid = false;

    msgBox.setAttribute(
      "style",
      `height:${msgBox.scrollHeight}px; overflow-y: hidden;`
    );

    const adjustTextareaHeight = () => {
      msgBox.style.height = "auto";
      msgBox.style.height = msgBox.scrollHeight + "px";
    };

    msgBox.addEventListener("input", adjustTextareaHeight);

    const setError = (input, errMessage) => {
      input.parentElement.classList.add("invalid");
      input.nextElementSibling.nextElementSibling.innerText = errMessage;
    };

    const removeError = (input) => {
      if (!input.parentElement.classList.contains("invalid")) return;

      input.parentElement.classList.remove("invalid");
      input.nextElementSibling.nextElementSibling.innerText = "";
    };

    const resetInputs = (...inputs) => {
      inputs.forEach((el) => (el.value = ""));
    };

    const isValidEmail = (email) => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    const isValidName = (name) => {
      const re = /^[a-zA-Z ]+$/;
      return re.test(name);
    };

    const isNotEmpty = (value) => {
      if (value === null || typeof value === "undefined") return false;
      if (value.trim() === "") return false;
      return true;
    };

    const emptyInputError = (input) => {
      if (isNotEmpty(input.value)) return false;
      setError(input, EMPTY_INPUT_ERROR);
      return true;
    };

    const validateNameInput = () => {
      if (emptyInputError(nameInput)) {
        isNameInputValid = false;
        return;
      }

      if (!isValidName(nameInput.value)) {
        setError(nameInput, INVALID_NAME_ERROR);
        isNameInputValid = false;
        return;
      }

      removeError(nameInput);
      isNameInputValid = true;
    };

    const validateEmailInput = () => {
      if (emptyInputError(emailInput)) {
        isEmailInputValid = false;
        return;
      }

      if (!isValidEmail(emailInput.value)) {
        setError(emailInput, INVALID_EMAIL_ERROR);
        isEmailInputValid = false;
        return;
      }

      removeError(emailInput);
      isEmailInputValid = true;
    };

    const validateMsgInput = () => {
      if (emptyInputError(messageInput)) {
        isMsgInputValid = false;
        return;
      }
      removeError(messageInput);
      isMsgInputValid = true;
    };

    const formStatusMessage = (status) => {
      if (formStatusMessageTimeout) clearTimeout(formStatusMessageTimeout);

      formStatus.classList.remove("hidden");
      formStatus.classList.add(`form__status--${status}`);
      formStatus.innerText =
        status === "success" ? FORM_SUCCESS_MSG : FORM_FAIL_MSG;

      document.querySelector(".form").scrollIntoView(true, {
        behavior: "smooth",
      });

      formStatusMessageTimeout = setTimeout(() => {
        formStatus.classList.remove(`form__status--${status}`);
        formStatus.classList.add("hidden");
        formStatus.innerText = "";
      }, FORM_MSG_STATUS_TIMER * 1000);
    };

    nameInput.addEventListener("keyup", validateNameInput);
    emailInput.addEventListener("keyup", validateEmailInput);
    messageInput.addEventListener("keyup", validateMsgInput);

    form.addEventListener("submit", (e) => {
      e.preventDefault();

      validateNameInput();
      validateEmailInput();
      validateMsgInput();

      if (!isNameInputValid || !isMsgInputValid || !isEmailInputValid) {
        return;
      }

      const xhr = new XMLHttpRequest();
      xhr.open("POST", "./form.php", true);

      xhr.onload = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          formStatusMessage("success");
          resetInputs(nameInput, emailInput, messageInput);
          adjustTextareaHeight();
        } else {
          formStatusMessage("error");
        }
      };

      const formData = new FormData(form);
      xhr.send(formData);
    });
  };

  const editCursor = () => {
    if (isTouchDevice()) return;
    const cursor = document.querySelector(".cursor");
    window.addEventListener("mousemove", (e) => {
      const { clientX: x, clientY: y } = e;
      cursor.style.left = x + "px";
      cursor.style.top = y + "px";
    });

    document.querySelectorAll(".btn").forEach((btn) => {
      btn.addEventListener(
        "mousemove",
        () => (cursor.style.transform = "translate(-50%, -50%) scale(1.4)")
      );

      btn.addEventListener(
        "mouseleave",
        () => (cursor.style.transform = "translate(-50%, -50%)")
      );
    });
  };

  responsiveNavigation();
  observeSections();
  observeNav();
  contactForm();
  portfolioSection();
  editCursor();
})();
